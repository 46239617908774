<template>
  <div>
    <a href="https://api.whatsapp.com/send?phone=0201101024741" target="_blank"
      class="btn btn-primary floating-btn">
      <i class="fab fa-whatsapp" style="font-size: 25px;"></i>
    </a>
    <!-- <a href="mailto:website@st-elevator.com" target="_blank"
      class="btn btn-primary floating-btn-2">
      <i class="fa fa-envelope" style="font-size: 25px;"></i>
    </a> -->
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('Downloads/Flow X/1.jpg');"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Flow X & Home Glide</h1>
          <div class="text-center">
            <p style="font-weight: 500;"> When you choose the Flow X stairlift or Home Glide, you ensure the best fit for your home.</p>
          </div>
        </div>
      </div>
    </div>
    <main style="height: 550px; background-color: #f5f5f5;">
      <div class="section section-images" style="height: 100%;">
        <div class="container">
          <div class="row justify-content-center mt-5">
            <div class="col-12 col-md-4">
              <div class="hero-images-container">
                <img src="Downloads/Flow X/flow-3d.png" alt="" />
              </div>
              <!-- <div class="hero-images-container-1">
                <img src="img/elevator.png" alt="" />
              </div>
              <div class="hero-images-container-2">
                <img src="img/elevator.png" alt="" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">What is Flow X?</h2>
            <h5 class="description" style="font-weight: 500;">
              Designed for seamless movement, the 
              Flow X provides outstanding levels of 
              comfort, confidence and craftsmanship.<br>
              Our most space-conscious stairlift, 
              Flow X is designed to blend into 
              your home effortlessly. Flow X is 
              fitted onto your staircase and not 
              your wall, allowing it to be installed 
              quickly and efficiently, with minimum 
              disruption to your home.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('Downloads/Flow X/2.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-secondary">
                  <span>
                    Advantage
                  </span>
                  <ul>
                    <li>
                      Comfort
                    </li>
                    <li>
                      Confidence
                    </li>
                    <li>
                      Craftsmanship
                    </li>
                    <li>
                      Space conscious
                    </li>
                  </ul>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <video
                class="image-container"
                style="aspect-ratio: 16/9;width: inherit;"
                controls="true"
              >
                <source src="Downloads/Flow X/vid.mp4" type="video/mp4">
              </video>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right "
                style="background-image: url('Downloads/Flow X/3.jpg'); margin-top: 6rem;"
              ></div>
              <h3>
                <strong style="color: #ff7129;">Streamline Elevator</strong> solution for Flow X stairlift <strong style="color: #ff7129;">ensures</strong>
              </h3>
              <p style="color: black;">
                The Flow X is designed to fit on narrow staircases, whether they are straight or curved. Fitted to your staircase and not your wall, the Flow X is installed quickly and efficiently, with minimum disruption to your home and blending into your home seamlessly.
              </p>
              <a class="btn btn-primary" download href="../../Downloads/Access_FlowX_Brochure_EN_email-1.pdf">
                <i class="fa fa-download"></i>
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">What is Home Glide?</h2>
            <h5 class="description" style="font-weight: 500;">
              The HomeGlide stairlift delivers a solution 
              for all straight staircase requirements 
              and budgets. Designed for effortless 
              simplicity with superior comfort and 
              value.<br> The HomeGlide is fitted onto your 
              staircase and not your wall. This allows 
              the lift to be installed quickly, efficiently 
              and with minimal disruption to your home.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('Downloads/Home Glide/1.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-secondary">
                  <span>
                    Advantage
                  </span>
                  <ul>
                    <li>
                      Slim Rail
                    </li>
                    <li>
                      Folding Rail
                    </li>
                    <li>
                      Seat belt
                    </li>
                    <li>
                      Call device
                    </li>
                  </ul>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <video
                class="image-container"
                style="aspect-ratio: 16/9;width: inherit;"
                controls="true"
              >
                <source src="Downloads/Home Glide/vid.mp4" type="video/mp4">
              </video>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right "
                style="background-image: url('Downloads/Home Glide/2.jpg'); margin-top: 6rem;"
              ></div>
              <h3>
                <strong style="color: #ff7129;">Streamline Elevator</strong> solution for Home Glide <strong style="color: #ff7129;">ensures</strong>
              </h3>
              <p style="color: black;">
                The ultimate marriage of design and functionality for any straight staircase.
              </p>
              <p style="color: black;">
                The HomeGlide stairlift delivers a solution for all straight staircase requirements and budgets. Designed for effortless simplicity with superior comfort and value.
              </p>
              <a class="btn btn-primary" download href="../../Downloads/Access_HomeGlide_Brochure_new_EN_email.pdf">
                <i class="fa fa-download"></i>
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'shaftless',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style scoped>
.floating-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 25rem;
  background-color: #26d969;
  animation: bounce 1s infinite alternate;
}
.floating-btn-2 {
  position: fixed;
  bottom: 20px;
  right: 100px;
  z-index: 1000;
  border-radius: 25rem;
  background-color: #2671d9;
  animation: bounce 1s infinite alternate;
}
@keyframes bounce {
  to { transform: scale(1.2); }
}
</style>
