<template>
  <div>
    <a href="https://api.whatsapp.com/send?phone=0201101024741" target="_blank"
      class="btn btn-primary floating-btn">
      <i class="fab fa-whatsapp" style="font-size: 25px;"></i>
    </a>
    <!-- <a href="mailto:website@st-elevator.com" target="_blank"
      class="btn btn-primary floating-btn-2">
      <i class="fa fa-envelope" style="font-size: 25px;"></i>
    </a> -->
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('Downloads/Altura Diamond/1.jpg');"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Altura</h1>
          <div class="text-center">
            <p style="font-weight: 500;"> Home lift, designed for you, using gearless belt technology Performance with style.</p>
          </div>
        </div>
      </div>
    </div>
    <main style="height: 550px; background-color: #f5f5f5;">
      <div class="section section-images" style="height: 100%;">
        <div class="container">
          <div class="row justify-content-center mt-5">
            <div class="col-12 col-md-4">
              <div class="hero-images-container">
                <img src="Downloads/Altura Diamond/altura-3d.png" alt="" />
              </div>
              <!-- <div class="hero-images-container-1">
                <img src="img/elevator.png" alt="" />
              </div>
              <div class="hero-images-container-2">
                <img src="img/elevator.png" alt="" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">What is Altura Diamond elevator?</h2>
            <h5 class="description" style="font-weight: 500;">
              Altura Diamond home lift, designed for you, using gearless belt technology
              Performance with style Smooth and silent ride.<br> The Altura Diamond incorporates innovative cog belt 
              technology.<br> The gearless drive system can offer unbelievable comfort and performance.
              A truly global Home lift for superior performances with speed from 0,15 up to 0,4 m/s 
              depending on local codes and market availbility.<br> Excellent materials and finishes. <br>
              Trust our designer’s choice for stylish combinations or feel free to design
              your own. The Altura Diamond can be customised in every details and dimensions
              perfectly matching your environment.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('Downloads/Altura Diamond/2.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-secondary">
                  <span>
                    Advantage
                  </span>
                  <ul>
                    <li>
                      Quieter Than A Quiet Room
                    </li>
                    <li>
                      No Oil/Lubrication Required
                    </li>
                    <li>
                      Extra Gentle Soft Start and Stop 
                    </li>
                    <li>
                      Advanced Error Notification System
                    </li>
                  </ul>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <video
                class="image-container"
                style="aspect-ratio: 16/9;width: inherit;"
                controls="true"
              >
                <source src="Downloads/Altura Diamond/vid.mp4" type="video/mp4">
              </video>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right "
                style="background-image: url('Downloads/Altura Diamond/1.jpg'); margin-top: 6rem;"
              ></div>
              <h3>
                <strong style="color: #ff7129;">Streamline Elevator</strong> solution for Altura Diamond elevator <strong style="color: #ff7129;">ensures</strong>
              </h3>
              <p style="color: black;">
                Experience luxury with the Altura Diamond, a versatile lift for a wide range of applications.
              </p>
              <p style="color: black;">
                The Altura Diamond Elevator is a modern vertical transportation solution designed
                for residential and commercial use, featuring a sleek design that integrates seamlessly into 
                various architectural styles. Its compact structure maximizes usable space, while advanced 
                technology ensures a smooth and quiet ride for enhanced user comfort.
              </p>
              <a class="btn btn-primary" download href="../../Downloads/Altura Diamond Catalogue 24.pdf">
                <i class="fa fa-download"></i>
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">What is Altura Platinum elevator?</h2>
            <h5 class="description" style="font-weight: 500;">
              The Altura Platinum hydraulic home lift fits everywhere
              The Altura Platinum incorporates proven and reliable technology.
              The hydraulic drive system can offer a wide range of choices, from a 
              minimal layout to a fully enclosed cabin without compromises in comfort 
              and performances.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('Downloads/Home Lift Images & Videos/1.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-secondary">
                  <span>
                    Advantage
                  </span>
                  <ul>
                    <li>
                      No Pit/Headroom
                    </li>
                    <li>
                      No Machine Room
                    </li>
                    <li>
                      Complied with European Safety Standards
                    </li>
                    <li>
                      Soft Start & Stop Technology
                    </li>
                  </ul>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <video
                class="image-container"
                style="aspect-ratio: 16/9;width: inherit;"
                controls="true"
              >
                <source src="Downloads/Home Lift Images & Videos/plat.mp4" type="video/mp4">
              </video>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right "
                style="background-image: url('Downloads/Home Lift Images & Videos/2.jpeg'); margin-top: 6rem;"
              ></div>
              <h3>
                <strong style="color: #ff7129;">Streamline Elevator</strong> solution for Altura Platinum elevator <strong style="color: #ff7129;">ensures</strong>
              </h3>
              <p style="color: black;">
                Experience luxury with the Altura Platinum, a versatile lift for a wide range of applications.
              </p>
              <p style="color: black;">
                The Altura Platinum is driven by proven and reliable hydraulic technology. Available in a wide range of sizes and configurations, the Altura Platinum is a highly adaptable lift that can be used in a wide range of public and private environments, indoors and outdoors. It is available with its own metallic shaft or can be installed in a masonry shaft.
              </p>
              <a class="btn btn-primary" download href="../../Downloads/Altura Platinum Catalogue 24.pdf">
                <i class="fa fa-download"></i>
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">What is Altura Gold elevator?</h2>
            <h5 class="description" style="font-weight: 500;">
              The smallest and most compact home lift in our product range. The lift 
              has been designed to meet all requirements for comfort, space and 
              design in narrow environments. The lift is available in different sizes, with 
              an option to personalize it with details such as glass walls, a range of 
              varied colours and different materials
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('Downloads/Altura Gold/1.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-secondary">
                  <span>
                    Advantage
                  </span>
                  <ul>
                    <li>
                      No Pit/Headroom
                    </li>
                    <li>
                      Low operation and maintenance cost
                    </li>
                    <li>
                      Complied with European Safety Standards
                    </li>
                    <li>
                      Small & Compact
                    </li>
                  </ul>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <video
                class="image-container"
                style="aspect-ratio: 16/9;width: inherit;"
                controls="true"
              >
                <source src="Downloads/Home Lift Images & Videos/gold.mp4" type="video/mp4">
              </video>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right "
                style="background-image: url('Downloads/Altura Gold/2.jpg'); margin-top: 6rem;"
              ></div>
              <h3>
                <strong style="color: #ff7129;">Streamline Elevator</strong> solution for Altura Gold elevator <strong style="color: #ff7129;">ensures</strong>
              </h3>
              <p style="color: black;">
                Experience small & compact lift with the Altura Gold, a versatile lift for a wide range of applications.
              </p>
              <p style="color: black;">
                The smallest and most compact home lift in our product range. The lift 
                has been designed to meet all requirements for comfort, space and 
                design in narrow environments. The lift is available in different sizes, with 
                an option to personalize it with details such as glass walls, a range of 
                varied colours and different materials
              </p>
              <a class="btn btn-primary" download href="../../Downloads/Altura Gold Catalogue 24.pdf">
                <i class="fa fa-download"></i>
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'shaftless',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style scoped>
.floating-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  border-radius: 25rem;
  background-color: #26d969;
  animation: bounce 1s infinite alternate;
}
.floating-btn-2 {
  position: fixed;
  bottom: 20px;
  right: 100px;
  z-index: 1000;
  border-radius: 25rem;
  background-color: #2671d9;
  animation: bounce 1s infinite alternate;
}
@keyframes bounce {
  to { transform: scale(1.2); }
}
</style>
